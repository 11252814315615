@import "../../styles/bootstrap-overrides";

.wrapper {
  @extend .p-4, .position-fixed;

  right: 0px;
  top: 0px;
}

.toastBody {
  @extend .pb-0, .mb-0;
}

.toastParagraph {
  @extend .pr-4, .mr-2;
}
