@import "../../../../styles/bootstrap-overrides";

.eventDisplayHorizontalWrapper {
  @extend .text-light, .d-flex, .w-100;

  height: 3.25rem;
  font-size: 2rem;

  .commandWrapper {
    .commandText {
      @extend .text-light, .d-inline;
    }
  }

  .chatGroupWrapper {
    @extend .text-left;

    .chatGroupImage {
      @extend .pr-2;

      height: 3.25rem;
    }
  }

  .commandWrapper,
  .chatGroupWrapper {
    @extend .d-inline;
  }
}
