@import "../../../styles/bootstrap-overrides";

/* this is to keep you wrapper on the middle of screen */
.wrapper {
  @extend .p-4;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.title {
}
